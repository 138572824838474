import React from 'react'
import {Link} from "gatsby";

export default (props) => (
    <section className="pt-9 pb-9 pt-md-12 pb-md-12 bg-light">
        <div className="container">
            <div className="row justify-content-center pb-5 pb-md-10">
                <div className="col-12 col-md-10 text-center">
                    <h2 className="h1">
                        Your first 10 million monthly events are free.
                    </h2>
                    <p className="lead text-gray-700">
                        Attribution is free while in beta. No contract or credit card required to get started.
                    </p>
                    <div className="d-grid gap-2 d-md-block">
                        <a className="btn btn-primary mx-md-3" href="/signup/">
                            Get Started for Free<i className="fe fe-arrow-right ms-2" />
                        </a>
                        <a className="btn btn-outline-primary" href="/contact/">
                            Contact Us
                        </a>
                    </div>

                </div>
            </div>
            <div className="row align-items-center gx-0">
                <div className="col-12 col-md-6">

                    <div className="card rounded-lg shadow-lg mb-6 mb-md-0" style={{zIndex: '100'}}>

                        <div className="card-body py-6 py-md-8">
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-9">

                                    <div className="text-center mb-5">
                                        <span className="badge rounded-pill bg-primary-soft">
                                            <span className="h6 fw-bold text-uppercase">Get Started Free</span>
                                        </span>
                                    </div>

                                    <div className="d-flex justify-content-center text-center">
                                        <span className="price display-4 mb-4">Free</span>
                                    </div>

                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Collect 10,000,000 Events / month. (We'll get in touch if you exceed that)
                                        </p>

                                    </div>

                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Your Data is Never Shared
                                        </p>

                                    </div>
                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Unlimited Data Sources &amp; Warehouses
                                        </p>

                                    </div>
                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Unlimited Reporting
                                        </p>

                                    </div>
                                    <div className="d-flex">

                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>

                                        <p>
                                            Unlimited Users &amp; Access
                                        </p>

                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            No Credit Card Needed
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <a href="/signup/" className="card-btn btn w-100 btn-lg btn-primary">
                            Get Started for Free
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 ms-md-n3">

                    <div className="card rounded-lg shadow-lg aos-init aos-animate" data-aos="fade-up"
                        data-aos-delay="200">

                        <div className="card-body py-6 py-md-8">
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-10">

                                    <p className="text-center">
                                        <span className="badge rounded-pill bg-primary-soft">
                                            <span className="h6 fw-bold text-uppercase">Grow Your Data</span>
                                        </span>
                                    </p>

                                    <div className="d-flex justify-content-center text-center">
                                        <span className="price display-4 mb-4">Enterprise</span>
                                    </div>

                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Collect an Unlimited number of Events
                                        </p>
                                    </div>

                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Contract, SLA, and Service Agreement
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Everything from your Free Account
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Link to="/contact" className="card-btn btn w-100 btn-lg btn-light btn-gray-600">
                            Contact Us
                        </Link>

                    </div>

                </div>
            </div>
        </div>
    </section>

)