import * as React from "react"
import SiteContainer from "../../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import funnel_steps from "../../../images/videos/funnel-steps.mp4"
import funnel_breakdown from "../../../images/videos/funnel-breakdown.mp4"
import funnel_opportunity from "../../../images/videos/funnel-opportunity.mp4"
import PricingSection from "../../../components/PricingSection";

const IndexPage = () => {
    return (
        <SiteContainer signupLink={'#get-started'}>
            <Helmet>
                <title>Firstparty Solutions - Analytics for Marketers and Developers</title>
                <meta name="description" content="Keep first-party cookies from your own domain, collect events in any browser, and move everything securely to your data warehouse." />
                
            </Helmet>

            <div className="section py-8 py-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6">
                            <h1 className="display-3 fw-bold">
                                Optimize Your Ideal Customer Journey
                            </h1>
                            <p className="lead text-muted mb-6">
                                Customizable funnel reports help you analyze and optimize your ideal customer journey from first visit through to lead, opportunity, and won deal.
                            </p>
                            <div className="d-grid gap-2 d-md-block mb-8 mb-md-0">
                                <a className="btn btn-primary mx-md-2" href="/signup/">
                                    Get Started for Free<i className="fe fe-arrow-right ms-2" />
                                </a>
                                <a className="btn btn-outline-primary" href="/contact/">
                                    Contact Us<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="img-skewed img-skewed-start mb-8 mb-md-0">
                                <div className="mw-md-130 img-skewed-item card bg-light mb-5" style={{width: '130%'}}>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>44,931</strong> <br />Homepage Viewed</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">100% Started</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "100%"}}
                                                         aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>14,378</strong> <br />Form Viewed</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">32% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "32%"}}
                                                         aria-valuenow="32" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>2,156</strong> <br />Lead Created</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">15% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "15%"}}
                                                         aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>93</strong> <br />Opportunity Created</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">11% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "11%"}}
                                                         aria-valuenow="11" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <p className="mb-0 small text-danger">Most Potential Improvement</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>69</strong> <br />Opportunity Won</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">74% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "74%"}}
                                                         aria-valuenow="74" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="pb-8 pb-md-11 pt-md-10">
                <Container>
                    <Row className="justify-content-between align-items-center">
                        <Col md={6} lg={6} className="order-md-2">
                            <div className="w-md-150 mb-5 mb-md-0">
                                <video autoPlay loop muted preload src={funnel_steps} className="img-fluid screenshot mt-6" />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h2>
                                Measure the effectiveness of your ideal customer journey
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Create a definition of your most common customer journeys in a few clicks, then report
                                on the performance in any time period.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Define funnels with an unlimited number of steps
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Customize the step name and order
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            See completion and abandon rates, as well as the average time between each step
                                        </p>
                                    </div>
                                    <a href="#get-started" className="btn btn-outline-primary btn-sm mt-5" onClick={() => window.firstparty.track('Funnel Signup Step', {step: 'Get Started Button Clicked'})}>Get Started</a>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6} lg={6} className="order-md-2">
                            <div className="w-md-150 mb-5 mb-md-0">
                                <video autoPlay muted preload src={funnel_breakdown} className="img-fluid screenshot" />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h2>
                                Inspect the performance of each step in your funnel
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Segment a single step of your customer journey into cohorts of source, medium,
                                campaign, or anything else.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Segment by source, medium, browser, or any custom data
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Measure exact participation breakdowns
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Learn exactly where to spend more time and budget to improve conversion rates
                                        </p>
                                    </div>
                                    <a href="#get-started" className="btn btn-outline-primary btn-sm mt-5" onClick={() => window.firstparty.track('Funnel Signup Step', {step: 'Get Started Button Clicked'})}>Get Started</a>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className="py-8 py-md-11 bg-white">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6">
                            <h2>See exactly which steps to improve</h2>
                            <p className="fs-lg text-gray-700">
                                Firstparty
                                shows the efficiency of each step in the funnel so you can see exactly where to focus and
                                improve.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Learn where customers are dropping off and where to focus your efforts
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            See how individual steps in the funnel are performing
                                        </p>
                                    </div>
                                    <a href="#get-started" className="btn btn-outline-primary btn-sm mt-5" onClick={() => window.firstparty.track('Funnel Signup Step', {step: 'Get Started Button Clicked'})}>Get Started</a>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="w-md-150 mb-5 mb-md-0">
                                <video autoPlay muted preload src={funnel_opportunity} className="img-fluid screenshot mt-6" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
